import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "page-header"
  }, [_c('div', {
    staticClass: "header-com"
  }, [_c('div', {
    staticClass: "header-wrap"
  }, [_c('div', {
    staticClass: "header-left"
  }, [_c('img', {
    staticClass: "header-logo",
    attrs: {
      "src": require("@images/logo.png"),
      "alt": "普健医疗"
    },
    on: {
      "click": _vm.goIndex
    }
  }), !_vm.isMyAccount && !_vm.isExternal ? _c('div', {
    staticClass: "active"
  }, [_c('router-link', {
    attrs: {
      "to": "/user-center/my-account"
    }
  }, [_c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("参与活动" + _vm._s(_vm.activeNum) + "次")])])], 1) : !_vm.isExternal ? _c('div', {
    staticClass: "active2"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("返回首页")])], 1) : _vm._e(), _vm.headerTit ? _c('span', {
    staticClass: "header-tit"
  }, [_vm._v(_vm._s(_vm.headerTit))]) : _vm._e()]), _c('div', {
    staticClass: "header-right"
  }, [_c('div', {
    staticClass: "search-box",
    style: {
      'margin-top': _vm.jiedanData == null || _vm.jiedanData == '' ? '36px' : '7px'
    }
  }, [_vm.jiedanData != null && _vm.jiedanData != '' ? _c('div', {
    staticClass: "jiedan",
    style: {
      width: _vm.searchWrapWidth + 'px'
    }
  }, [_c('div', [_c('label', [_vm._v("温馨提示：")]), _vm._v(_vm._s(_vm.jiedanData) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "search-wrap",
    style: {
      width: _vm.searchWrapWidth + 'px'
    }
  }, [_c('b-autocomplete', {
    ref: "searchInput",
    staticClass: "search-text",
    style: {
      width: _vm.inputWidth + 'px'
    },
    attrs: {
      "data": _vm.searchList,
      "placeholder": "请输入商品名称/商品编码/拼音首字母/生产厂家",
      "field": "title",
      "loading": _vm.isFetching,
      "clearable": _vm.keepFirst,
      "open-on-focus": _vm.keepFirst
    },
    on: {
      "typing": _vm.getAsyncData,
      "blur": _vm.onBlur
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.getAsyncData.apply(null, arguments);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onSearch(_vm.searchKey);
      }
    },
    model: {
      value: _vm.searchKey,
      callback: function callback($$v) {
        _vm.searchKey = $$v;
      },
      expression: "searchKey"
    }
  }), _c('input', {
    staticClass: "search-btn",
    attrs: {
      "type": "button",
      "value": "搜索"
    },
    on: {
      "click": function click($event) {
        return _vm.onSearch(_vm.searchKey);
      }
    }
  }), _vm.isShowSearchWrap ? _c('div', {
    staticClass: "search-result-wrap",
    style: {
      width: _vm.searchWrapWidth + 'px'
    }
  }, [_c('ul', [_vm.isHistory ? [_vm._l(_vm.searchList, function (item, ind) {
    return _c('li', {
      key: ind,
      class: {
        active: _vm.index == ind
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.clickSearchHistory(item);
        }
      }
    }, [_c('span', {
      staticClass: "span-left"
    }, [_vm._v(_vm._s(item))]), _c('span', {
      staticClass: "span-right"
    }, [_vm._v("搜索历史")])]);
  }), _c('li', {
    staticClass: "del-all-history",
    on: {
      "click": _vm.delHistory
    }
  }, [_vm._v(" 删除全部 ")])] : _vm._l(_vm.lenovoSearchList, function (item, ind) {
    return _c('li', {
      key: ind,
      class: {
        active: _vm.index == ind
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.clickSearchKey(item);
        }
      }
    }, [_c('span', {
      staticClass: "span-left"
    }, [_vm._v(_vm._s(item.value))])]);
  })], 2)]) : _vm._e()], 1)]), _c('ul', {
    staticClass: "header-right-list"
  }, [!_vm.flag ? _c('li', {
    on: {
      "mouseenter": function mouseenter($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onMouseIn($event);
      },
      "mouseleave": function mouseleave($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onMouseOut($event);
      },
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.goCartLink.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_01.png")
    }
  }), _c('p', [_vm._v("购物车")]), (_vm.cartSum < 1 ? false : true) ? _c('p', {
    staticClass: "carNum"
  }, [_vm._v(" " + _vm._s(_vm.cartSum >= 100 ? "..." : _vm.cartSum) + " ")]) : _vm._e(), _vm.isHover ? _c('div', {
    staticClass: "hover-list"
  }, [!_vm.loading ? _c('div', {
    staticClass: "hover-title"
  }, [_c('p', [_vm._v("共" + _vm._s(_vm.cartSum) + "件商品")]), _c('p', {
    staticClass: "hover-empty",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleEmpty.apply(null, arguments);
      }
    }
  }, [_vm._v("清空")])]) : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "la-ball-clip-rotate la-dark"
  }, [_c('div')]) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "hover-wrap"
  }, _vm._l(_vm.cartList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "hover-left"
    }, [_c('dl', [_c('dt', [_c('img', {
      attrs: {
        "src": item.picPath,
        "alt": ""
      }
    })]), _c('dd', [_c('p', [_vm._v(" 【" + _vm._s(item.specifications) + "】" + _vm._s(item.productName) + " ")]), _c('p', [_vm._v(" 生产厂家:"), _c('span', [_vm._v(_vm._s(item.produceFactory))])]), item.nearEffectivePeriod ? _c('p', [_vm._v(" 效期:"), _c('span', [_vm._v(_vm._s(_vm._f("formatDate")(item.nearEffectivePeriod)))])]) : _c('p', [_c('span', [_vm._v("效期:")])])])]), _c('div', {
      staticClass: "hover-right"
    }, [_c('p', {
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.handleDelete(item.productId);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("./images/icon_delete.png"),
        "alt": ""
      }
    })]), _c('p', [_vm._v("x" + _vm._s(item.totalNumber))]), _c('p', {
      staticClass: "hover-red"
    }, [_vm._v(" ￥"), _c('span', [_vm._v(_vm._s(_vm._f("NumFormat")(Number(item.preferentialPrice))))])])])]);
  }), 0) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('li', {
    on: {
      "click": _vm.goodsLink
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_02.png")
    }
  }), _c('p', [_vm._v("订单")])]), _c('li', {
    staticClass: "enshrine",
    on: {
      "click": _vm.goodsLinkEnshrine
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_03.png")
    }
  }), _c('p', [_vm._v("我的收藏")])]), _c('li', {
    staticClass: "goodsLinkMessage",
    on: {
      "click": _vm.goodsLinkMessage
    }
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_044.png")
    }
  }), _c('p', [_vm._v("消息")]), _vm.isRead.noReadCount > 0 ? _c('p', {
    staticClass: "carNum"
  }, [_vm._v(_vm._s(_vm.isRead.noReadCount >= 100 ? "99+" : _vm.isRead.noReadCount) + " ")]) : _vm._e()])])])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };