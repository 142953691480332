export var topRightList = [{
  value: "个人中心",
  icon: require("./images/icon_user.png"),
  imgStyle: {
    width: "20px",
    height: "20px"
  },
  href: ""
}, {
  value: "手机商城",
  icon: require("./images/icon_phone.png"),
  imgStyle: {
    width: "20px",
    height: "22px"
  },
  href: ""
}];