import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home-top"
  }, [_c('div', {
    staticClass: "home-top-cont"
  }, [_c('div', {
    staticClass: "top-left"
  }, [_c('span', {
    staticClass: "welcome"
  }, [_vm._v("欢迎来普健商城！")]), _vm.isLogin ? _c('div', {
    staticClass: "logined"
  }, [_vm.isExternal ? _c('span', {
    staticClass: "login-info"
  }, [_vm._v(_vm._s(_vm.userName))]) : _c('span', {
    staticClass: "login-info"
  }, [_c('span', {
    staticClass: "login-info-name"
  }, [_vm._v(_vm._s(_vm.loginCompanyName))]), _vm._v("【切换】")]), _c('span', {
    staticClass: "to-login",
    on: {
      "click": _vm.toLogOut
    }
  }, [_vm._v("退出")]), !_vm.isExternal ? _c('ul', {
    staticClass: "user-list"
  }, [_vm._l(_vm.userList, function (item, index) {
    return _c('li', {
      key: index,
      class: index === 0 ? 'selected' : '',
      attrs: {
        "title": item.name
      },
      on: {
        "click": function click($event) {
          index === 0 ? undefined : _vm.onCheckUser(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), _c('b-loading', {
    attrs: {
      "is-full-page": false,
      "can-cancel": false
    },
    model: {
      value: _vm.isLoading,
      callback: function callback($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  })], 2) : _vm._e()]) : _c('p', [_c('span', {
    staticClass: "to-login",
    on: {
      "click": _vm.goLogin
    }
  }, [_vm._v("立即登录")])]), _vm._m(0), _c('p', [_c('a', {
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'v2-home'
        });
      }
    }
  }, [_vm._v("新版入口")])])]), _vm.dataList.visit_number ? _c('marquee', {
    attrs: {
      "width": "350",
      "behavior": "scroll",
      "direction": "left",
      "align": "middle",
      "scrollamount": "2"
    }
  }, [_c('span', [_vm._v("今日商城浏览用户数" + _vm._s(_vm.dataList.visit_number) + "，今日商城下单客户数" + _vm._s(_vm.dataList.customer_num_order) + "，今日商城订单量" + _vm._s(_vm.dataList.order_num_total) + " ")]), !_vm.isExternal ? _c('span', [_vm._v("本月[" + _vm._s(_vm.loginCompanyName) + "]已节省¥ " + _vm._s(_vm.dataList.save_amount || 0) + "，超" + _vm._s(_vm.dataList.save_amount_rank * 100 || 0) + "%用户， 参与活动" + _vm._s(_vm.dataList.attend_activity_num || 0) + "，超" + _vm._s(_vm.dataList.attend_activity_rank * 100 || 0) + "%用户， 消费金额¥ " + _vm._s(_vm.dataList.consume_amount || 0) + "，超" + _vm._s(_vm.dataList.consume_amt_rank * 100 || 0) + "%用户")]) : _vm._e()]) : _vm._e(), _c('ul', {
    staticClass: "top-right"
  }, _vm._l(_vm.topRightList, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "item-style",
      on: {
        "click": function click($event) {
          return _vm.goFun(index);
        }
      }
    }, [_vm.isLogin || !_vm.isLogin && index !== 0 ? _c('div', [_c('img', {
      style: item.imgStyle,
      attrs: {
        "src": item.icon
      }
    }), _vm._v(" " + _vm._s(item.value) + " ")]) : _vm._e(), _vm.isShowCode ? _c('div', {
      staticClass: "code-wrap"
    }, [_c('span', {
      staticClass: "close",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.closeFun.apply(null, arguments);
        }
      }
    }, [_vm._v("✕")]), _c('div', {
      staticClass: "code-cont"
    }, [_c('img', {
      attrs: {
        "src": _vm.qrCodeUrl
      }
    }), _c('p', {
      staticClass: "info"
    }, [_vm._v("扫码安装普健商城APP")])])]) : _vm._e()]);
  }), 0)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('a', {
    attrs: {
      "href": "/普健.url"
    }
  }, [_vm._v("生成快捷方式")])]);
}];
export { render, staticRenderFns };